<template>
  <div class="CategorySelectionView">
    <div class="header">
      <div
        class="exitButton --global-clickable"
        @click="$router.push('home')">
        Exit
      </div>
      <div class="title">
        {{ $t('library') }}
      </div>
    </div>
    <div class="body">
      <div class="categories">
        <div
          v-for="(category, i) of categories"
          :key="i"
          class="Category --global-clickable"
          @click="gotoBrowse(category)">
          <svgicon
            class="Category__icon"
            :icon="`categories/${category.id}`" />

          <span class="Category__label">{{ $t(`dashboard.general.categoryLabels.${category.id}`) }}</span>
          <span class="Category__range">{{ category.range }}</span>
          <svgicon
            class="Category__arrowIcon"
            icon="chevron-down" />
        </div>
      </div>
    </div>
  </div>
</template>

<translations>
  library: 'Library'
  library_no: 'Bibliotek'
</translations>

<script>
import categories from '../categories';

export default {
    inject: ['config'],
    created() {
        this.categories = categories;
    },
    methods: {
        gotoBrowse(category) {
            this.$router.push(`browse/${category.range}`);
        }
    }
};
</script>

<style scoped lang="scss">
.CategorySelectionView {
    position: relative;
    display: flex;
    flex-direction: column;
}

.header {
    height: 4em;
    display: flex;
    padding: 0em 1em;
    align-items: center;
    position: relative;
}
.title {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 5em;
    right: 4em;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 120%;
    color: white;
}
.exitButton {
    color: rgba(white, 0.5);
}
.body {
    flex: 1;
    overflow-y: auto;
}

.Category {
    padding: 2em;

    display: flex;
    align-items: center;

    border-bottom: 1px solid rgba(white, 0.05);
    color: rgba(white, 0.8);
}
.Category__icon {
    $size: 1.5em;
    width: $size;
    height: $size;
    fill: rgba(white, 0.9);
    margin-right: 1em;
}
.Category__label {
    font-size: 120%;
}
.Category__range {
    flex: 1;
    margin-left: 0.5em;
    font-size: 120%;
}
.Category__arrowIcon {
    $size: 1em;
    width: $size;
    height: $size;
    fill: rgba(white, 0.8);
    transform: rotate(-90deg);
}
</style>
